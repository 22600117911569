.table-wrapper {
    overflow-x: auto;
}
.table-wrapper.user-list thead th:first-child {
    width: 70px;
    text-align: center;
}
.table-wrapper.user-list td:first-child,
.table-wrapper.user-list th:first-child {
    width: 70px;
    text-align: center;
}
.table-wrapper.user-list td:nth-of-type(2),
.table-wrapper.user-list th:nth-of-type(2) {
    width: 80px;
    text-align: center;
}
.table-wrapper.user-list td:nth-of-type(3),
.table-wrapper.user-list th:nth-of-type(3) {
    min-width: 200px;
}
.table-wrapper.user-list td:nth-of-type(5),
.table-wrapper.user-list th:nth-of-type(5) {
    width: 145px;
}
.table-wrapper.user-list td:nth-of-type(6),
.table-wrapper.user-list th:nth-of-type(6) {
    width: 200px;
}
.table-wrapper.user-list td:nth-of-type(7),
.table-wrapper.user-list th:nth-of-type(7) {
    width: 82px;
}
.table-wrapper.user-list td:nth-of-type(8),
.table-wrapper.user-list th:nth-of-type(8) {
    width: 120px;
}
.table-wrapper.user-list td:last-child,
.table-wrapper.user-list th:last-child {
    width: 150px;
}
.sort-outer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.user-info-sec {
    display: flex;
    align-items: center;
}
.user-info-sec .user-image {
    margin-right: 10px;
}
.user-info-sec .user-image figure {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
}
.user-info-sec .user-image img {
    width: 100%;
    height: 100%;
}
.action-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
