.profile-detail-inner {
    padding: 15px;
    border: 1px solid #0091b2;
    border-radius: 5px;
    background-color: #fff;
}
.user-profile-wrap .sub-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #0091b2;
}
.user-profile-info {
    display: flex;
}
.user-upload-file .close-icon {
    position: absolute;
    top: 8px;
    right: 8px;
}
.user-upload-file .close-icon .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    color: red;
    transition: all 0.3s ease-in;
    cursor: pointer;
}
.user-upload-file {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    background-color: #f2f2f2;
    border: 1px dashed #00536c;
}
.user-upload-file input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
}
.user-upload-file .upload-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.user-upload-file .upload-icon figure {
    width: 50px;
    height: 50px;
    margin: 0 auto 10px;
}
.user-upload-file .upload-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.user-upload-file p {
    margin-bottom: 15px;
    font-size: 14px;
    white-space: nowrap;
}
.user-profile-info .col-right {
    padding: 15px 0 0 35px;
    width: calc(100% - 150px);
}
.register-id {
    padding-top: 25px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.user-profile-wrap button {
    height: 42px;
    border-radius: 4px;
    padding: 6px 16px;
}
.user-profile-wrap .breadcrumb-right {
    display: flex;
}
.user-profile-wrap .breadcrumb-right .edit-btn button {
    color: #fff !important;
}
.user-profile-wrap .breadcrumb-right > div:not(:last-child) {
    margin-right: 15px;
}

/* tab-section-style */
.tabs-section {
    padding-top: 20px;
}
.tabs-section .MuiTabPanel-root {
    padding: 15px 0;
}
.tabs-section .MuiTabs-flexContainer {
    justify-content: center;
}
.tabs-section .MuiButtonBase-root.MuiTab-root {
    margin-right: 20px;
    border: 1px solid #0091b2;
    color: #0091b2;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 15px 10px;
    min-height: 44px;
    font-family: "soleilregular" !important;
}
.tabs-section .MuiTabs-indicator {
    display: none;
}
.tabs-section .MuiTabs-root .MuiButtonBase-root.Mui-selected {
    background-color: #0091b2;
    color: #fff;
}
.tab-content {
    padding: 15px 15px 0;
    border: 1px solid #00536c;
    border-radius: 5px;
    background-color: #fff;
    font-family: "soleilregular";
}
.tab-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.tab-col {
    max-width: 33.33%;
    margin-bottom: 15px;
    padding: 0 8px;
    flex: 0 0 33.33%;
}
.user-info {
    display: flex;
}
.user-info label {
    margin-right: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #00536c;
}
.user-info p {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: #00536c;
}
.user-info {
    margin-bottom: 12px;
}
.user-info:last-child {
    margin-bottom: 0;
}
.tab-box {
    height: 143px;
    padding: 15px 15px 30px;
    background: #e8f6fa;
    border: 1px solid #e8f6fa;
    border-radius: 4px;
}
.tab-box-inner {
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0091b2;
}
.tab-info {
    margin-bottom: 10px;
    font-size: 48px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0.34px;
    color: #0091b2;
}
.tab-description {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.34px;
    color: #0091b2;
}
.tab-image {
    padding: 5px 20px 20px;
}
.tab-image figure {
    width: 64px;
    height: 64px;
}
.tab-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: invert(54%) sepia(97%) saturate(4148%) hue-rotate(162deg) brightness(88%) contrast(101%);
}
.tab-left {
    width: calc(100% - 104px);
    overflow: hidden;
    word-break: break-all;
}
