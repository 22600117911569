.accountWrapper {
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
}
.right-section {
    width: calc(100% - 676px);
    position: relative;
}
.right-inner {
    width: 530px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}
.page-title {
    margin-bottom: 44px;
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.27px;
    color: #0091b2;
}
.page-title.bottom-spacing {
    margin-bottom: 15px;
}
.page-description {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.2px;
    text-align: center;
    color: #707070;
}
.form-wrapper {
    width: 100%;
    padding: 24px 0 0;
}
.right-section label {
    margin-bottom: 5px;
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.15px;
    color: #00536c;
}
.input-row {
    width: 100%;
    margin-bottom: 25px;
    position: relative;
}
.eye-icon {
    position: absolute;
    right: 15px;
    top: 65%;
    transform: translateY(-50%);
}
.password-row {
    width: 100%;
    position: relative;
}
.password-row.top-spacing {
    margin-top: 25px;
}
.forgot-link {
    text-align: right;
    padding: 5px 0 0;
}
.forgot-link a {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.13px;
    text-align: center;
    color: #0091b2;
    text-decoration: none;
}
.forgot-link a:hover {
    text-decoration: underline;
}
.remember-me {
    padding: 20px 0;
    display: flex;
    align-items: center;
}
.remember-me p {
    margin-right: 10px;
    font-size: 17px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.00035px;
    color: #00536c;
}
.remember-me .MuiSvgIcon-root,
.password-row .MuiSvgIcon-root {
    color: #008ab4;
}
.btn-wrap button {
    text-transform: capitalize;
    width: 100%;
}
.btn-wrap button a {
    text-decoration: none;
    color: #fff;
}
.btn-wrap .back-login-btn {
    padding-top: 20px;
}
.btn-wrap button {
    height: 60px;
}

.error-box {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.error-box .error-description {
    margin-left: 9px;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.0048px;
    color: #ff0000;
}
.right-arrow-section {
    position: absolute;
    right: 20px;
    top: 20px;
}
.btn-wrap.reset-btn {
    padding-top: 25px;
}
.remember-me .MuiCheckbox-root {
    margin-left: -10px;
}
@media (max-width: 1024px) {
    .right-section {
        width: calc(100% - 350px);
    }
    .right-inner {
        width: 100%;
        padding: 5%;
    }
    .page-title {
        margin-bottom: 20px;
        font-size: 25px;
    }
    .page-description {
        font-size: 20px;
    }
    .form-wrapper {
        padding: 15px 0 0;
    }
    .input-row {
        margin-bottom: 15px;
    }
    .right-section label {
        font-size: 16px;
    }
    .remember-me {
        padding: 15px 0;
    }
    .form-wrapper.top-spacing {
        padding-top: 10px;
    }
    .password-row.top-spacing {
        margin-top: 15px;
    }
    .btn-wrap.reset-btn {
        padding-top: 20px;
    }
    .btn-wrap button {
        height: 50px;
    }
}

@media (max-width: 767px) {
    .right-section {
        width: 100%;
    }
    .right-inner {
        padding: 5%;
    }
    .page-title {
        margin-bottom: 15px;
        font-size: 22px;
    }
    .page-description {
        font-size: 18px;
    }
    .form-wrapper {
        padding: 10px 0 0;
    }
    .input-row {
        margin-bottom: 15px;
    }
    .right-section label {
        font-size: 14px;
    }
    .forgot-link a {
        font-size: 14px;
    }
    .remember-me {
        padding: 10px 0;
    }
    .remember-me p {
        font-size: 16px;
    }
    .btn-wrap .back-login-btn {
        padding-top: 15px;
    }
    .form-wrapper.top-spacing {
        padding-top: 0;
    }
    .password-row.top-spacing {
        margin-top: 15px;
    }
    .btn-wrap.reset-btn {
        padding-top: 15px;
    }
    .btn-wrap button {
        height: 45px;
    }
}
