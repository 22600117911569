.section-title {
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #5282f0;
}
.card-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.card-col {
    max-width: 33.33%;
    flex: 0 0 33.33%;
    padding: 0 8px;
}

.card-box {
    height: 143px;
    padding: 15px 15px 30px;
    background: #e8f6fa;
    border: 1px solid #e8f6fa;
    border-radius: 4px;
}
.card-box-inner {
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0091b2;
}
.card-info {
    margin-bottom: 5px;
    font-size: 60px;
    font-weight: 600;
    line-height: 64px;
    letter-spacing: 0.34px;
    color: #0091b2;
}
.card-description {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.34px;
    color: #0091b2;
}
.card-image {
    padding: 5px 20px 20px;
}
.card-image figure {
    width: 64px;
    height: 64px;
}
.card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: invert(54%) sepia(97%) saturate(4148%) hue-rotate(162deg) brightness(88%) contrast(101%);
}
.card-left {
    width: calc(100% - 104px);
    overflow: hidden;
    word-break: break-all;
}
.card-section .dashboard-card {
    padding-bottom: 15px;
}

.firebase-text {
    margin-top: 15px;
    display: flex;
    align-items: center;
    padding: 15px;
    background: #edf7fa;
}
.firebase-head {
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    color: #0091b2;
}
.firebase-link {
    margin-left: 15px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: #0091b2;
    text-decoration: none;
}
.firebase-link:hover {
    text-decoration: underline;
}
.firebase-text .card-row {
    align-items: center;
}
