.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(
        to right,
        rgba(0, 145, 178, 0) 0%,
        rgba(0, 145, 178, 0.9) 25%,
        rgba(0, 145, 178, 0.9) 75%,
        rgba(0, 145, 178, 0) 100%
    );
    width: 100%;
    padding: 20px 0;
}
.loader.none {
    display: none;
}
.loader.block {
    display: block;
}
.loader p {
    font-size: 20px;
    color: #fff;
    text-align: center;
}
