.profile-inner,
.change-password-wrap {
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid #00536c;
    border-radius: 5px;
    background-color: #fff;
}
.user-profile-detail {
    display: flex;
}
.profile-wrap .sub-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #0091b2;
}
.user-profile-detail .col-right {
    padding: 15px 0 0 35px;
    width: calc(100% - 150px);
}
.profile-info {
    display: flex;
}
.profile-info label {
    margin-right: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #00536c;
}
.profile-info p {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: #00536c;
}
.profile-info {
    margin-bottom: 12px;
}
.profile-info:last-child {
    margin-bottom: 0;
}
.change-password-wrap .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
}
.password-col {
    max-width: 33.33%;
    padding: 0 8px;
    flex: 0 0 33.33%;
}
.form-content {
    width: 100%;
    position: relative;
}
.form-content label {
    margin-bottom: 5px;
    display: inline-block;
    color: #707070;
    font-family: "soleilregular";
}
.form-content .eye-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}
.form-content .MuiSvgIcon-root {
    color: #008ab4;
}
.error-msg-2 {
    margin-top: 5px;
    display: flex;
    align-items: flex-start;
}
.error-msg-2 img {
    margin-right: 6px;
}
.error-msg-2 span {
    margin-top: 0;
}
.btn-group {
    padding-top: 25px;
    display: flex;
}
.btn-group .cancel-btn {
    margin-right: 15px;
}
.btn-group button {
    width: 200px;
    height: 42px;
}
.cancel-btn button:hover {
    background-color: #d32f2f;
    color: #fff;
    border-color: transparent;
}
.upload-file .MuiCircularProgress-root {
    position: absolute;
    bottom: 0;
    right: 0;
}
