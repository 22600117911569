.breadcrumb-wrap.notification-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.breadcrumb-wrap.notification-btn a {
    text-decoration: none;
}
.select-box.top-spacing {
    margin-top: 23px;
}

/* .date-picker */
.date-picker {
    width: 100%;
}
.date-picker input {
    font-size: 14px;
    color: #707070;
}
.to-text {
    font-size: 15px;
    color: #707070;
    font-weight: 600px;
}
