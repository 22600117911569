.pagination-wrap {
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.filterWrap {
    display: flex;
    align-items: center;
}
.countWrap {
    padding-left: 10px;
}
.countWrap p {
    font-size: 14px;
    margin-bottom: 0;
}
.countWrap p b {
    font-weight: 600;
}
.pagination {
    display: flex;
    align-items: center;
}
button.MuiPaginationItem-previousNext {
    display: none;
}
.pagination-sec .left-arrow,
.pagination-sec .right-arrow {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.enable .MuiButtonBase-root {
    color: #000 !important;
}
.disable .MuiButtonBase-root {
    color: rgba(0, 0, 0, 0.54) !important;
}
.pagination-sec .MuiSvgIcon-root {
    font-size: 18px;
}
/* 
.pagination-wrap p {
    font-size: 14px;
}
.pagination-wrap p b {
    font-weight: 600;
} */
