/* .terms-conditions-content {
    padding-bottom: 17px;
}
.terms-conditions-content:last-child {
    padding-bottom: 10px;
}
.terms-conditions-content .terms-heading {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: 0.41px;
    color: #5282f0;
}
.terms-conditions-content .terms-descriprion {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
    color: #5282f0;
}
.terms-descriprion.copyright {
    font-size: 18px;
    font-weight: 500;
}
.terms-descriprion a {
    text-decoration: underline;
    font-size: 16px;
    font-weight: 300;
    color: #5282f0;
} */
/* .content-description-terms {
    background: rgba(82, 130, 240, 0.16);
} */
.content-description-terms .wrapper {
    width: 100%;
    max-width: 100%;
}
/* .content-description-terms .cms-header h1 {
    font-size: 20px;
} */
