.header {
    width: calc(100% - 265px);
    height: 70px;
    padding: 10px 20px;
    background: #edf7fa;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    left: 265px;
    z-index: 999;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 25%);
}
.header-inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.title {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    color: #0091b2;
}
.user-menu,
.header-right {
    display: flex;
    align-items: center;
}
.notification-icon figure {
    width: 20px;
    cursor: pointer;
    margin-right: 15px;
}
.user-icon figure {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 50%;
    overflow: hidden;
}
.user-icon figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.user-menu .user-name a,
.user-menu .user-name {
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    color: #0091b2;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s ease-in;
}
.user-menu .user-name a:hover,
.user-menu .user-name:hover {
    text-decoration: underline;
}
.menu-section {
    display: flex;
    align-items: center;
}
.menu-section a {
    display: flex;
    align-items: center;
}
.menu-section figure {
    width: 26px;
    margin-right: 12px;
}
.menu-section img {
    width: 100%;
    filter: invert(54%) sepia(97%) saturate(4148%) hue-rotate(162deg) brightness(88%) contrast(101%);
}
.css-6hp17o-MuiList-root-MuiMenu-list {
    background: #edf7fa;
}
.css-8je8zh-MuiTouchRipple-root {
    display: none;
}
.menu-section span {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #0091b2;
    font-family: "soleilregular";
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
    background-color: rgba(0, 145, 178, 0.2) !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover span {
    font-weight: 600;
}
.header-left {
    display: flex;
    align-items: center;
}
.menu-icon {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}
.menu-icon .MuiSvgIcon-root {
    color: #5282f0;
    font-size: 32px;
}
.MuiMenuItem-root a {
    text-decoration: none;
}
.user-icon {
    margin-right: 5px;
}
.header-width {
    width: 100%;
    left: 0;
}
