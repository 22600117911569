.table-wrapper.notification-list thead th:first-child {
    width: 80px;
    text-align: center;
}
.table-wrapper.notification-list td:first-child,
.table-wrapper.notification-list th:first-child {
    width: 80px;
    text-align: center;
}
.table-wrapper.notification-list td:nth-of-type(2),
.table-wrapper.notification-list th:nth-of-type(2) {
    width: 150px;
}
.table-wrapper.notification-list td:nth-of-type(3),
.table-wrapper.notification-list th:nth-of-type(3) {
    width: 250px;
}
.table-wrapper.notification-list td:nth-of-type(4),
.table-wrapper.notification-list th:nth-of-type(4) {
    width: 150px;
}
.table-wrapper.notification-list td:last-child,
.table-wrapper.notification-list th:last-child {
    width: 115px;
}
