.notification-breadcrumb {
    padding-bottom: 15px;
    display: flex;
    align-items: center;
}
.notification-breadcrumb span {
    transition: 0.3s ease all;
    font-size: 15px;
    color: #0091b2;
    font-weight: 400;
}
.notification-breadcrumb span:hover {
    font-weight: 600;
}
.notification-breadcrumb .MuiSvgIcon-root {
    width: 17px;
    margin: 0 5px;
    color: #0091b2;
}
.notification-breadcrumb a {
    text-decoration: none;
}
