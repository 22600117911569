.left-section {
    width: 676px;
    background: url("../../../assets/images/login/admin-bg.png");
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
}

.left-section-inner {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.left-section-inner .logo figure {
    width: 514px;
}
.left-section-inner .logo img {
    width: 100%;
    object-fit: cover;
}
.page-title-left {
    padding-top: 50px;
}
.page-title-left .title {
    text-align: center;
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    line-height: 1.5;
}

@media (max-width: 1024px) {
    .left-section {
        width: 350px;
    }
    .left-section-inner .logo figure {
        width: 85%;
        margin: 0 auto;
    }
    .page-title-left {
        padding-top: 35px;
    }
    .page-title-left .title {
        font-size: 17px;
    }
}
@media (max-width: 767px) {
    .left-section {
        width: 100%;
    }
    .left-section-inner {
        width: 100%;
        padding: 40px;
    }
    .left-section-inner .logo figure {
        width: 80%;
        margin: 0 auto;
    }
    .page-title-left {
        padding-top: 25px;
    }
    .page-title-left .title {
        font-size: 16px;
        line-height: 1.7;
    }
}
