.sidebar {
    width: 265px;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    background: #edf7fa;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    border-right: 1px solid #0091b2;
}
.sidebarInner {
    height: 100%;
    overflow-y: auto;
}
.sidebar-header {
    height: 115px;
    padding: 30px;
    display: flex;
    align-items: center;
}
.sidebar-logo figure {
    width: 55px;
    height: 55px;
    margin-right: 25px;
    border-radius: 50%;
    overflow: hidden;
}
.sidebar-logo figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.sidebar-title h2 {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    color: #0091b2;
}
.sidebar-main {
    height: calc(100vh - 115px);
    overflow-y: auto;
}
.sidebar-menu a {
    padding: 10px 15px;
    height: 44px;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #2461d6;
    transition: 0.3s ease-in;
}
.sidebar-menu .sub-menu {
    background: #edf7fa;
    /* display: none; */
}
.sidebar-menu .sub-menu a {
    padding: 10px 15px 10px 45px;
}
.sidebar-menu a:hover {
    background-color: rgba(0, 145, 178, 0.2);
    font-weight: 600;
}
.sidebar-menu i.icon {
    width: 22px;
    height: 22px;
    margin-right: 15px;
    background-size: 100% 100% !important;
    transition: 0.3s ease-in;
}
.sidebar-menu a .dashboard {
    background: url("../images/sidebar/home.svg") center center no-repeat;
}
.sidebar-menu a .user {
    background: url("../images/sidebar/user.svg") center center no-repeat;
}
.sidebar-menu a .content {
    background: url("../images/sidebar/content_management.svg") center center no-repeat;
}
.sidebar-menu a .privacy-policy {
    background: url("../images/sidebar/privacy.svg") center center no-repeat;
}
.sidebar-menu a .terms-conditions {
    background: url("../images/sidebar/terms.svg") center center no-repeat;
}
.sidebar-menu a .faq {
    background: url("../images/sidebar/faq.svg") center center no-repeat;
}
.sidebar-menu a .notification {
    background: url("../images/sidebar/notification.svg") center center no-repeat;
}
.sidebar-menu .sub-menu {
    list-style: none;
}
.sidebar-menu .parent-menu a.arrow {
    position: relative;
}
.sidebar-menu .parent-menu a.arrow:before {
    width: 7px;
    height: 7px;
    border-bottom: 2px solid #2461d6;
    border-right: 2px solid #2461d6;
    content: "";
    display: block;
    pointer-events: none;
    position: absolute;
    right: 16px;
    top: 52%;
    transition: 0.3s ease-in;
}
.sidebar-menu .parent-menu.down-arrow a.arrow:before {
    margin-top: -6px;
    transform: rotate(45deg);
}
.sidebar-menu .parent-menu.up-arrow a.arrow:before {
    margin-top: -2px;
    transform: rotate(225deg);
    top: 50%;
}
.sidebar-menu .parent-menu a.arrow:hover:before {
    border-color: #5282f0;
}
.sidebar-menu ul.sub-menu li a:hover {
    background-color: rgba(0, 145, 178, 0.2);
    font-weight: 600;
}

a.active {
    background-color: rgba(0, 145, 178, 0.2);
    font-weight: 600;
}
.sidebar-width {
    width: 0;
}
