.sort-by {
    width: 20px;
    display: inline-block;
    margin-left: 10px;
    position: relative;
}
.sort-by-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sort-outer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.sort-by .sort-by-inner svg {
    opacity: 0.3;
}
.sort-enable .MuiSvgIcon-root {
    opacity: 1 !important;
}
.sort-by-inner svg {
    cursor: pointer;
}
