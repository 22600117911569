.faq-content {
    border: 1px solid #0091b2;
    border-radius: 5px;
}
.faq-content .faq-header {
    padding: 15px;
    border-bottom: solid 1px #0091b2;
    background-color: #0091b2;
    display: flex;
    align-items: center;
}
.faq-header h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    color: #fff;
    letter-spacing: 0.5px;
}
.faq-header span {
    margin-left: 15px;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.3px;
    font-weight: 500;
    color: #fff;
}
.faq-content-description {
    height: calc(100vh - 181px);
    padding: 15px;
    background: #edf7fa;
    overflow-y: auto;
}
.faq-main-content {
    border: 1px solid #0091b2;
    border-radius: 5px;
    margin-bottom: 15px;
}
.faq-main-content .content-header {
    padding: 15px;
    border-bottom: solid 1px #0091b2;
}

.content-header h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.3px;
    color: #00536c;
}
.content-header h4 span {
    margin-left: 5px;
    font-weight: 400;
    color: #00536c;
}
.faq-content-main {
    padding: 15px 15px 7px;
}
.faq-content-main .questions {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: 0.41px;
    color: #00536c;
}
.faq-content-main .answer {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
    color: #707070;
}
.faq-main-content:last-child {
    margin-bottom: 0;
}
